<template>
    <div class="progress-circle" :data-content="content ?? `${Math.round(progress)}%`"></div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    // percent of progress
    progress: {
        type: Number,
        default: 0,
    },
    content: {
        type: String,
        default: undefined,
    },
    gradientFrom: {
        type: String,
        default: "#2ab756",
    },
    gradientTo: {
        type: String,
        default: "#b7eb8f",
    },
});

const progressString = computed(() => `${props.progress}%`);
</script>

<style scoped>
div.progress-circle {
    @apply w-full h-full rounded-full flex;
    transition: all 0.3s;
    background: conic-gradient(v-bind(gradientFrom) v-bind(progressString), v-bind(gradientTo) 0deg);
}

div.progress-circle::after {
    @apply text-xs font-semibold flex flex-col justify-center w-full rounded-full bg-white text-center;
    content: attr(data-content);
    margin: 5px;
}
</style>
